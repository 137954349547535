@import "../../assets/scss/custom-var";

.microsoft_img {
    width: 48px;
    height: 48px;
    margin: 0 1rem 0 0;
}

.container_center {
    align-items: center;
    justify-content: center;
    display: flex;
    height: calc(100vh - 54px);
}