.keyItemListHalide{
  background-color: #81F3E3;
}

.secondaryKeyItemList {
  // @extend .tag;
  background-color: #FFE1A5;
  // border: solid 1px #FFE1A5 !important;

  &:hover {
      background-color: #fad386;
      // border: solid 1px #f7ae1c !important;
  }
}

.secondaryKeyItemListSelected{
  background-color: greenyellow !important;
}

.keyDetailsDataHeader{
  position: sticky;
  top: 0px;
  background-color: #eee;
}

.keyItemListHalideSelected{
  background-color: yellow;
}


  .myModel{
    height: 100% !important;
    max-width: 80% !important;
    margin-top: 0px !important;
  }

  .dialogWrapper{
    padding-top: 0px !important;
    background-color: #f5f5f5 !important;
    max-height: calc(100% - 63px) !important;
    overflow: auto !important;
  }

  .modelContent{
    height: 90% !important;
  }

  .tagsWrapper{
    padding: 0.875rem 1rem;
    margin-top: 0rem;
    display: flex;
    justify-content: space-between;
  }
  
  .pointer{
    cursor: pointer;   
  }
  
  .tag{
    &:hover, &:focus, &:active{
      background-color: #81F3E3;
      border:solid 1px #02C2A7;
    }
    background-color: #CEFFF8;
    border:solid 1px #CEFFF8;
    border-radius: 1.5rem;
    font-size: 0.813rem;
    padding: 0.125rem 0.688rem;      
  }
  
  .tagCount {
    display: inline-block;
    padding: 0.35em 0.35em;            
    font-size: 0.688rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    
    position: absolute;
    background-color: red;
    transform: translate(-50%,-50%)!important;
    border-radius: 50rem;
    left: 100%;
    top: 0;
  }
  
  
  ul.searchKeys {
    display: flex;
    margin: 0px;
    padding: 0px;
    user-select: none;
  }
  
  .searchKeys > li{
    cursor: pointer;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    margin-left: 4px;
  }
  
  .mLabel{
    text-align: left;
    font: normal normal 600 18px/32px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #394659;
    opacity: 1;
   margin-left: 20px;
  }
   
  .btn{
      position: absolute;
      top: 16px;
      right: 61px;
      padding: 0px;
  }
  .btnDoc{
    width: 124px;
    height: 35px;
    background: #E63946 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    color: #fff;
    border:0px;
    text-align: center;
      font: normal normal normal 13px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      letter-spacing: 0px;
  }
  .mHeader {
      height: 66px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 10px #c7cddc7a;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
  }
  .cbtn{
    border: 0px !important;
    background: transparent;
  }

  .accordionbutton::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(.7) !important;
  }
  .accordionbutton:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  }

  .pnew{
    border:0px !important;
  }
  .pborderbb{
    border-bottom: 2px !important;
    border-bottom: 2px !important;
    border-color: #ddd;
    padding: 10px 10px;
  }
  .ptitle{
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    letter-spacing: 0px;
    color: #2E2E2E;
    opacity: 1;
    text-decoration: none;
  }
  .ptitlefsl{
    font: normal normal 400 16px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    
  }
   .ptitlefs{
    font: normal normal 600 16px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 10px;
    
  }
  .circlediv{
    width: 25px;
    background: #eee;
    height: 25px;
    display: inline-block;
    border-radius: 50px;
    position: relative; 
    padding: 3px 0px;
    background: #d5d1e3b8 0% 0% no-repeat padding-box;
/*    opacity: 0.68;*/
    left:10px;
  }
  .circlespan{
    text-align: center;
    font: normal normal bold 14px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #4A4747;
    opacity: 1;
  }

  .newAlert{
    background: #ef2f2f;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 0px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    position: absolute;
    top: 8px;
    display: inline-block;
  }
  .upleft{
    margin-left:20px;
  }
  .downleft{
    margin-left:20px;
  }
  .class_top{
    padding-bottom: 20px;
  }
  .numdiv{
    width: 34px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 14px/22px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #455B7C;
    opacity: 1;
    margin-left:10px;
  }

  .desc_div{
    min-width: 54px;
    height: 27px;
    /* UI Properties */
    background: #81F3E3 0% 0% no-repeat padding-box;
    border: 1px solid #45D2BD;
    border-radius: 5px;
    opacity: 1;
    font: normal normal normal 16px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #2E2E2E;
    opacity: 1;
    text-align: center;
    padding-top:2px;
    display: inline-block;
  }

  .secondaryDesc_div{
    min-width: 54px;
    height: 27px;
    /* UI Properties */
    background: #FFE1A5 0% 0% no-repeat padding-box;
    border: 1px solid #fad386;
    border-radius: 5px;
    opacity: 1;
    font: normal normal normal 16px/20px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #2E2E2E;
    opacity: 1;
    text-align: center;
    padding-top:2px;
    display: inline-block;
  }

  // .class_parent {
  //   background: #FCFCFC 0% 0% no-repeat padding-box;
  //   box-shadow: inset 0px 3px 6px #00000017;
  //   opacity: 1;
  // }
      
  // .class_child {
  //   max-width: 550px;
  //   text-align: left;
  //   font: normal normal normal 14px/22px Open Sans;
  //   letter-spacing: 0px;
  //   color: #455B7C;
  //   opacity: 1;
  //   position: relative;
  //   top: 20px;
  //   padding-left: 40px !important;
  //   padding-right: 40px !important;
  //   overflow-y: scroll;
  //   max-height: calc(100vh - 250px);
  // }

  .class_parent {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000017;
    opacity: 1;
    overflow-y: scroll;
    max-height: calc(100vh - 250px);
  } 
  
  .class_child {
    max-width: 550px;
    text-align: left;
    font: normal normal normal 14px/22px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #455B7C;
    opacity: 1;
    position: relative;
    top: 20px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  
  .ptitle:hover {
    color: #000;
    text-decoration: none;
}