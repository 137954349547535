.yellow_bg{
  background-color: yellow;
}

body {
  background: #F9F9F9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.searchtxt {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 144px;
  height: 30px;
  text-align: left;
  margin-right: 30px;
  font: normal normal 600 22px/30px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0px;
  color: #1D3557;
  opacity: 1;
}

.divhw {
  width: 830px;
  margin: auto;
}

.bgw {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  padding: 16px 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.edtch {
  text-align: left;
  font: normal normal 600 14px/25px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0.14px;
  color: #18891C;
  opacity: 1;
}

.spandesc {
  text-align: left;
  font: normal normal normal 16px/25px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0.16px;
  color: #394659;
  opacity: 1;
  display: block;
}

.headtitle {
  text-align: left;
  font: normal normal 600 14px/18px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0px;
  color: #232323;
  opacity: 1;
  display: block;
  margin-bottom: 10px;
}

.yellowclr {
  background: yellow;
}

.navbb {
  border-bottom: 2px solid #dee2e6;
}

.nav-link {
  border-radius: 0px;
}

.navlink {
  border: 0px;
  text-align: left;
  font: normal normal normal 14px/19px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0px;
  color: #232323 !important;
  opacity: 1;
  background: transparent !important;
  display: block;
  padding: 0.5rem 1rem;
  // border:0px !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #232323;
}

.nav-link.active,
.show>.nav-link {
  color: #E53946 !important;
  border: 0px !important;
  background-color: transparent !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
  border-bottom: 2px solid !important;
  border-bottom-color: #E53946 !important;
  border-radius: 0px !important;
}