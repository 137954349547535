$text-primary: #707070;
$dark-gray: #2E2E2E;
$gray-300: #424242;
$gray-400: #616161;
$gray-200: #d6d6d6;
$gray-100: #d5d3d3;
$lgt-gray: #f5f5f5;
$lgt-green: #0eb4a0;
$dark-blue: #05558f;
$blue-200: #2a6ea0;
$blue-100: #60b8f8;
$header-bg: #1A304F;
