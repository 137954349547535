@import "../../assets/scss/custom-var";
.card_main {
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000001a;
  border: 0 none !important;
  border-radius: 0 !important;
  cursor: pointer;
  img {
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
  .c_footer {
    background: $dark-blue;
    min-height: 52px;
    display: flex;
    align-items: center;
    .f_text {
      color: #f9f9f9;
      width: 60%;

      span {
        padding-right: 0.5rem;
        font-size: 1.3rem;
        img {
          position: relative;
          top: -0.1rem;
        }
        .img_text {
          width: 22px;
          height: 22px;
        }
        .img_video {
          width: 26px;
          height: 26px;
        }
        .img_pin {
          width: 25px;
          height: 20px;
        }
        .img_powerpoint {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
  .card_title {
    color: $dark-gray;
    font-weight: 600;
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 1400px) {
  .c_footer {
    .f_text {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 991px) {
  .card_main {
    .card_image,
    .card_title {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
