@import "../../assets/scss/custom-var";

.ico_section {
  span {
    padding-right: 0.5rem;
    font-size: 1rem;
  }

  .img_powerpoint {
    width: 0.9rem;
  }
}
.card_title {
  color: $dark-gray;
  font-weight: 600;
}
