.divider_sm {
    color: #7CACCE;
}
.input_icons i {
    position: absolute;
}
  
.input_icons {
    width: 100%;
 }
  
.icon {
    padding: 13px;
    color: #424242;
    min-width: 50px;
    text-align: center;
}
.icon_border{
    height: 30px;
    border: 1px solid #ccc;
    opacity: 1;
    left: 2.8rem;
    top: 0.6rem;
}
  
.input_field {
    width: 100%;
    padding: 10px 55px;
    outline: transparent;
    border-color: transparent;
    border-radius: 5px;
}
  // .dropdown_content {
  //   position: absolute;
  //   width: 100%;
  //   background: #fff;
  //   height:350px;
  //   border-radius: 5px;
  //   top: 3.2rem;
  //   overflow: auto;
  //   box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 40%);
  // }
  .dropdown_content {
    position: absolute;
    width: 100%;
    background: #fff;
    height:350px;
    border-radius: 5px;
    top: 3.2rem;
    height: auto;
    box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 40%);
    }
  .dropdown:hover .dropdown_content {
    display: block;
  }
  .dropdown_content .alink {
    display: block;
    color: #6c757d;
    text-decoration: none;
    padding: 2px 16px;
    font-size: 14px;;
    cursor: pointer;
  }
  .dropdown_content .alink_more {
    display: block;
    color: red;
    text-decoration: none;
    padding: 2px 16px;
    font-size: 14px;;
    cursor: pointer;
  }
  .dropdown_content .alink:hover {
    color: #FFFFFF;
    background-color: #1a304f;
    margin-bottom: 1px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  .yellow_bg{
    background-color: yellow;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6c757d; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6c757d; 
  }
  .ip_parent{
    display: block;
    margin-top:5px;
  }
  .ip_child{
    display: block;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 15px;
  }
  .desc_parent{
    display: block;
  }
  .desc_child{
    display: block;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 15px;
  }
  .tag_parent{
    display: block;
  }
  .tag_child{
    display: block;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 15px;
  }
  .tag_child_left{
      padding-right:10px;
  }
  .file_parent{
    display: block;
  }
  .file_child{
    display: block;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 15px;
  }
  .file_child_left{
    padding-right:10px;
}
.file_child_icon{
    height: 15px;
    width: 15px;
}
