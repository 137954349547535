@import "../../assets/scss/custom-var";
.card_white {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px #0000001A;
  border: 0 none;
}
.img_wrapper {
  background: $lgt-gray;
  z-index: 1030;
  height: calc(100vh - 54px);
  top: 54px;
  right: 0;
  position: fixed;
  z-index: 1000;
}
.ico_bg {
  background: $blue-100;
  color: #FFFFFF;

  img {
    width: 1.3rem;
  }
}
.card_title {
  color: $dark-gray;
  font-weight: 600;
}
.pin_overlap {
  position: absolute;
  left: 1.8rem;
  top: 0.7rem;
}
.field_ico {
  padding-left: 3rem !important;
}
@media only screen and (max-width: 991px) {
.img_wrapper {
  z-index: 11;
}
}