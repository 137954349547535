@import "../../assets/scss/custom-var";

.card_white {
min-height: 100%;
background: #FFFFFF;
box-shadow: 0px 0px 8px #0000001A;
.card_title {
  color: $dark-gray;
  font-weight: 600;
}
  .c_footer {
    background: #fff;
    min-height: 52px;
    display: flex;
    align-items: center;
  }
  .ico_section {
    span {
      padding-right: 0.5rem;
      font-size: 1rem;
    }
    .img_powerpoint {
      width: 0.8rem;
    }

  }

@media only screen and (max-width: 1400px) {
    .c_footer {
        .f_text {
            width: 100% !important;
        }
    }
 }
 @media only screen and (max-width: 991px) {
  .card_main {
         .card_image, .card_title{
          display: flex;
          justify-content: center;
          text-align: center;
         }
  }
}
}