@import "../../assets/scss/custom-var";

.card_white {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px #0000001A;

}
.card_title {
  color: $dark-gray;
  font-weight: 600;
}

.ico_bg {
  background: $blue-100;
  color: #FFFFFF;

  img {
    width: 1.3rem;
  }
}

.modal_backdrop {
  background-color: #f5f5f5;
}

.chat_wrapper {
  box-shadow: 0px 3px 8px #00000029;
  background: $lgt-gray;
  z-index: 1030;
  height: calc(100vh - 54px);
  top: 54px;
  right: 0;
  position: fixed;
  z-index: 1000;

}
.chat_messages {
  padding: 1rem;
  height: calc(100vh - 245px);
}

.chat_card {
  min-height: 106px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.chat_info, .chat_bottom_bar {
  background: #F5F5F5
}

.chk_label {
  label{
    padding-top: 0.1rem;
    padding-left: 0.2rem;
  }

}
@media only screen and (max-width: 991px) {
  .chat_wrapper {
    z-index: 11;
  }
  }