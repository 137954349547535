@import "../scss/custom-var";

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: $text-primary;
  background: #f5f5f5;
}

h1 {
  font-size: 1.2rem;
}

.back-arrow {
  color: $lgt-green;
  font-size: 1.8rem;
}

.navbar.bg-dark {
  background-color: $header-bg !important;

  .searchbox {
    min-height: 32px;
    background: $blue-200;
    border: 1px solid $blue-200;
    border-radius: 8px !important;
    color: #aac5d9;

    &::placeholder {
      color: #aac5d9;
    }
  }
}

.navbar {
  .dropdown-toggle::after {
    color: $white;
  }
}

.search {
  right: 2rem;
  top: 0.4rem;
  position: relative;
  color: $white;
}

form.c-form {
  label {
    font-size: 14px;
    font-weight: 600;
    color: $gray-300;
    margin-bottom: 0.5rem;
    padding-left: 0;
  }

  .form-control,
  .form-select {
    font-size: 14px;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    padding: 0.8rem;
  }
}

.dropdown-no-border,
.select-bordered {
  select {
    border: 0;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0;
    padding: 0 2rem 0 0;
    font-weight: 600;
    color: $text-primary;
  }
}

.select-bordered {
  select {
    border: 1px solid #e6e6e6 !important;
    min-height: 40px;
    width: 100%;
    border-radius: 0;
    padding: 1rem;
    background-position-x: 97%;
    background-position-y: 1rem;
    font-weight: normal;
  }
}

.filter-collapse {
  .card {
    width: 400px;
    position: absolute;
    top: 51px;
    right: 0;
    z-index: 10;

    .card-body {
      padding: 0 1.5rem;
      text-align: left;
    }
  }
}

.close {
  color: $gray-400;
}

.divider-hr {
  border-top: 1px solid #f3f1f1;
  display: flex;
  width: 100%;
}
.divider-btm {
  border-bottom: 1px solid #E6E6E6;;
  display: flex;
  width: 100%;
}

.divider-hr-dark {
  border-top: 1px solid $gray-200;
  display: flex;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.title {
  color: $gray-300;
  font-weight: 600;
  padding: 1rem 0;
}

.form-check-input:checked[type="checkbox"] {
  background: $dark-blue url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}

.form-check-input {
  width: 22px;
  height: 22px;
  border: 2px solid $gray-200;

  &:focus {
    box-shadow: none;
    border-color: $gray-200;
  }
}

.form-check-lable {
  color: $text-primary;
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
}

.checks {
  .form-check-inline {
    margin-bottom: 1rem;

    label {
      cursor: pointer;
    }
  }
}

.form-label {
  color: $gray-400;
  padding: 0 1rem;
  cursor: pointer;
  margin-bottom: 0;
}

.badge {
  &.bg-info {
    min-width: 78px;
    min-height: 30px;
    background: $white !important;
    border-radius: 2px;
    border: 1px solid $gray-100;
    color: $gray-300 !important;
    font-weight: normal;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0.5rem 0;
    // cursor: pointer;
  }

  &.selected {
    background: #caeae6 !important;
    border: 0 none !important;
  }

  .close {
    font-size: 1.2rem;
  }
}

.scroll-collapse {
  overflow-x: hidden !important;
  height: calc(100vh - 54px);
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  min-width: 69px;
  min-height: 35px;
  background: $lgt-green;
  color: $white;
  border: 0 none;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    background: $lgt-green;
    box-shadow: none !important;
  }
}

.btn-secondary {
  min-width: 69px;
  min-height: 35px;
  background: transparent;
  color: $gray-300;
  border: 0 none;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    background: #e6e6e6;
    box-shadow: none;
    color: $gray-300;
  }
}

.btn-bordered-c {
  min-width: 69px;
  min-height: 35px;
  background: $white;
  color: $lgt-green;
  border: 1px solid $lgt-green;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    background: #069483;
    box-shadow: none !important;
  }
}

.btn-row {
  .btn {
    margin-right: 1rem;
  }
}

.btn-link {
  color: $text-primary;
  text-decoration: none;

  &:hover {
    color: $text-primary;
  }
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:before {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

.ripple:focus:before {
  transition: all 0.5s ease-out;
  opacity: 0;
  width: 160px;
  height: 160px;
  margin-top: -80px;
  margin-left: -80px;
}

.popover-view {
  &.btn-link {
    color: $text-primary !important;
    padding-top: 0;
  }

  i {
    font-size: 1.2rem;
  }

  i.arrow-dw {
    font-size: 0.8rem;
  }

  .form-label {
    position: relative;
    top: -3px;
  }
}

.c-popover {
  min-width: 131px;
  min-height: 71px;
  background: $dark-blue;

  .btn-link {
    color: $white;
    text-decoration: none;

    i {
      font-size: 1.5rem;
    }

    label {
      font-size: 1.3rem;
    }

    &:active,
    &:hover {
      background-color: $blue-200;
    }
  }
}

.bs-popover-bottom>.popover-arrow::after {
  border-bottom-color: $dark-blue;
}

.gray-circle-sm {
  width: 34px;
  height: 34px;
  background: #f3f1f1;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.txt-tag {
  color: $lgt-green;
  font-size: 0.8rem;
  border-right: 1px solid #e6e6e6;
  padding: 0 0.5rem 0.2rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0 none;
  }
}

.profile-info {
  h5 {
    color: #2e2e2e;
    font-size: 14px;
  }

  .subtitle {
    font-size: 14px;
  }
}

.tag-gray {
  min-width: 76px;
  min-height: 23px;
  background: #e6e6e6;
  border-radius: 2px;
  font-style: italic;
  color: $text-primary;
  padding: 0.3rem;
}

table {
  table-layout: fixed;
  color: $text-primary !important;
  background: $white;

  th.six {
    width: 15%;
  }

  th.two {
    width: 20%;
  }

  th.four {
    width: 15%;
  }

  th.three {
    width: 15%;
  }

  th.five {
    width: 15%;
  }

  th.last {
    width: 20%;
  }

  thead {
    color: #2e2e2e;
    font-weight: 600;
  }

  tr {
    border-bottom: 1px solid #e6e6e6;
  }

  .txt-tag {
    font-size: 1rem;
    color: $text-primary;
  }
}

.table> :not(:first-child) {
  border-top: 1px solid #e6e6e6 !important;
}

.table> :not(caption)>*>* {
  border-width: 0;
  padding: 1.2rem 1rem;
}

.divider-right {
  border-right: 1px solid #e6e6e6;
}

.font-weight-6 {
  font-weight: 600;
}

#popover-contained {
  &.popover {
    border: 0 none !important;
    box-shadow: 0px 0px 20px #00000029;
    background: $white;

    .popover-arrow::after {
      box-shadow: 0px 0px 20px #00000029;
      border-bottom-color: $white !important;
    }
  }
}

.text-truncate-multiline {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.count {
  .btn-link {
    div {
      font-size: 0.8rem;
    }
  }
}

.info-section {
  h5 {
    color: $gray-300;
  }
}

.tag-feedback {
  min-height: 22px;
  background: #cfebff;
  font-size: 12px;
  color: $gray-300;
  padding: 5px;
  overflow: hidden;
  width: 120px;
}

.chats {
  .form-control-lg {
    font-size: 1rem !important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.btnUpload {
  border: solid 1px $lgt-green;
  color: $lgt-green;
  font-size: 14px;
  border-radius: 0;
}

.vdoIconWrapper {
  background-color: $blue-100;
  color: $white;
  padding: 14px;
  height: calc(100% - 23px);
  position: relative;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactTags__selected {
  margin-bottom: 20px;
}

.ReactTags__tagInput {
  font-size: 16px;
}

.ReactTags__tag {
  min-width: 78px;
  min-height: 30px;
  background: #caeae6 !important;
  border-radius: 2px;
  border: 1px solid $gray-100;
  color: $gray-300 !important;
  font-weight: normal;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem;
  border: 0 none;
}

.ReactTags__selected .ReactTags__remove {
  font-size: 1rem;
  background: transparent;
  border: 0;
  padding: 0 0 0 10px;
  font-weight: bold;
  color: $gray-300;
}
input.ReactTags__tagInputField {
  min-width: 250px;
}
.container-wht-bg {
  background: $white;
  box-shadow: 0px 0px 8px #0000001a;
  padding: 2.3rem;
}

.add-tags {
  i {
    color: $lgt-green;
    font-size: 2rem;
  }
}

.add-ico {
  i {
    font-size: 1.5rem;
  }
}

.custom-file-input {
  color: transparent;
  width: 150px;
  padding: 0.2rem 0 0 2rem;
  min-height: 42px;
  background: $white;
  color: $lgt-green;
  border: 1px solid $lgt-green;
  cursor: pointer;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Choose File";
  color: $lgt-green;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.pt-sm {
  padding-top: 0.1rem;
}

.dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: auto !important;
}

.circle {
  display: inline-block;
  background-color: $white;
  border-radius: 50%;
}

.circle-inner {
  color: $dark-blue;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 40px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.eLwbhX {
  input {
    color: #FFFFFF !important;
    font-size: 0.9rem !important;
  }

}

.sc-gsDKAQ {
  padding: 0 1.5rem !important;
}

.ekwNMM {
  fill: #FFF !important;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.file-type-ico {
  width: 20px;
  height: 20px;
}

.list-group-item-warning {
  background-color: #fffaeb !important;
}

.breadcrumb {
  font-size: 0.9rem;
}
.all-list {
  img {
    top: 0.2rem;
    position: relative;
  }
}
.chip {
  background-color: #069483;
}
@media only screen and (max-width: 991px) {
  .filter-collapse {
    .card {
      top: 118px;
    }
  }

  .scroll-collapse {
    overflow-x: hidden !important;
    height: calc(100vh - 124px);
  }
}

@media only screen and (max-width: 991px) {

  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  #no-more-tables {
    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;

      &:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
        content: attr(data-title);
      }
    }
  }
}